<script setup>
	import { ref, reactive, computed, onMounted } from 'vue'
	import Header from './components/Header.vue'
	import Main from './components/Main.vue'
	import Footer from './components/Footer.vue'
	import Spinner from './components/Spinner.vue'
	import Thankyou from './components/Thankyou.vue'
	import { setCookie, getCookie, advertiserMapping } from './constants/selectOptions.js'
	import { sendToEzsend } from './utilities/phoneUtilities.js'
	import axios from 'axios'
	import qs from 'qs';

	let backupCity = null;
	let backupState = null;
	let backupZip = null;
	let LpRequestId = null;

	// split test variables
	let mainHeadline = "Find Out If You Qualify For Economic Debt Relief in 2025"
	let mainSubHeadline = "Your search for debt relief begins here!"
	let variantName = "CUSTOM-FFAPP-CONTROL"

	let acContactId = null
	let lpLeadId = null
	const questionOrder = ref([
		'amount',
		'income',
		'name',
		'email',
		'address',
		'dob',
		'phone'
	])
	let arrayData = {
		collections: null,
		credit_score: null,
		delinquencies: null,
		total_unsecured_debt: null
	}
	let readyToRedirect = true
	const formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 0
	});
	const questions = ref({
		amount: {
			id: 'amount',
			headline: variantName === "CUSTOM-FFAPP-VAR3" ? 'Choose your debt amount' : 'How much debt do you have?',
			showHeadlines: true,
			showProgress: false,
			validated: computed(() => {
				return questions.value.amount.value === null ? false : true
			}),
			type: 'range',
			dollarValue: computed(() => {
				if (questions.value.amount.value === '0') {
					return 'Less than $5,000'
				} else if (questions.value.amount.value === '380') {
					return 'More than $100,000'
				} else {
					var numberValue = 5000 + (questions.value.amount.value * 250)
					return formatter.format(numberValue)
				}
			}),
			numberValue: computed(() => {
				if (questions.value.amount.value === '0') {
					return '0'
				} else if (questions.value.amount.value === '380') {
					return '101000'
				} else {
					return 5000 + (questions.value.amount.value * 250)
				}
			}),
			chat: "<p>Hi there! I'm Catherine, I'm here to help you with your personalized debt savings estimate.</p>",
			value: ref(window.localStorage.getItem("amount") ? JSON.parse(window.localStorage.getItem("amount")) : 62),
			min: 0,
			max: 380,
			backgroundGradientValue: computed(() => {
				var value = ((questions.value.amount.value-questions.value.amount.min)/(questions.value.amount.max-questions.value.amount.min))*100
				return 'linear-gradient(to right, #3C91E6 0%, #3C91E6 ' + value + '%, #eee ' + value + '%, #eee 100%)'
			}),
			submit: (optionValue) => {
				console.log(optionValue)
				localStorage.setItem('amount', optionValue)
				proceed()
			}
		},
		employed: {
			id: 'employed',
			headline: 'Are you currently employed?',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return questions.value.employed.value === null ? false : true
			}),
			type: 'buttons',
			options: [
				{
					text: "Yes",
					value: true,
				},
				{
					text: "No",
					value: false,
				}
			],
			chat: "<p>The best debt relief option could be different depending on your employment status</p>",
			value: ref(window.localStorage.getItem("employed") ? JSON.parse( window.localStorage.getItem("employed")) : null),
			submit: (optionValue) => {
				localStorage.setItem('employed', optionValue)
				proceed()
			}
		},
		income: {
			id: 'income',
			headline: 'What is your approximate monthly income?',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return questions.value.income.value === null ? false : true
			}),
			type: 'buttons',
			options: [
				{
					text: "$5000+",
					value: "5500",
				},
				{
					text: "$4000 - $5000",
					value: "4500",
				},
				{
					text: "$3000 - $4000",
					value: "3500",
				},
				{
					text: "$2000 - $3000",
					value: "2500",
				},
				{
					text: "$1000 - $2000",
					value: "1500",
				},
				{
					text: "I don't have monthly income.",
					value: "0",
				}
			],
			chat: "<p>Calculate your available gross income including wages, retirement, investments, and rental properties.</p>",
			value: ref(window.localStorage.getItem("income") ? JSON.parse( window.localStorage.getItem("income")) : null),
			submit: (optionValue) => {
				localStorage.setItem('income', optionValue)
				proceed()
			}
		},
		assets: {
			id: 'assets',
			headline: 'What is the total estimated value of your assets?',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return questions.value.assets.value === null ? false : true
			}),
			type: 'buttons',
			options: [
				{
					text: "Less than $50k",
					value: "Less than $50k",
				},
				{
					text: "$50-$500k",
					value: "$50-$500k",
				},
				{
					text: "$500k-$1m",
					value: "$500k-$1m",
				},
				{
					text: "More than $1 million",
					value: "More than $1 million",
				}
			],
			chat: "<p>Calculate your available assets including real estate, vehicles, currency and investments.</p>",
			value: ref(window.localStorage.getItem("assets") ? window.localStorage.getItem("assets") : null),
			submit: (optionValue) => {
				localStorage.setItem('assets', optionValue)
				proceed()
			}
		},
		realEstate: {
			id: 'realEstate',
			headline: 'Do you own real estate?',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return questions.value.realEstate.value === null ? false : true
			}),
			type: 'buttons',
			options: [
				{
					text: "Yes",
					value: true,
				},
				{
					text: "No",
					value: false,
				}
			],
			chat: "<p>Owning real estate could open up additional debt relief options which might benefit you.</p>",
			value: ref(window.localStorage.getItem("realEstate") ? JSON.parse( window.localStorage.getItem("realEstate")) : null),
			submit: (optionValue) => {
				localStorage.setItem('realEstate', optionValue)
				proceed()
			}
		},
		credit: {
			id: 'credit',
			headline: 'What is your approximate credit score?',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return questions.value.credit.value === null ? false : true
			}),
			type: 'buttons-icons',
			options: [
				{
					text: "Poor",
					value: "Poor",
					description: "500 and Below",
					icon: "fa-face-frown-slight",
					iconColor: "#A71D31",
				},
				{
					text: "Average",
					value: "Average",
					description: "500 - 600",
					icon: "fa-face-meh",
					iconColor: "#F9892A",
				},
				{
					text: "Good",
					value: "Good",
					description: "600 - 700",
					icon: "fa-face-smile",
					iconColor: "#5DD39E"
				},
				{
					text: "Great",
					value: "Great",
					description: "700 and Above",
					icon: "fa-face-grin",
					iconColor: "#60D394"
				}
			],
			chat: "<p>The best options for you could vary depending on your credit score.</p>",
			value: ref(window.localStorage.getItem("credit") ? window.localStorage.getItem("credit") : null),
			submit: (optionValue) => {
				localStorage.setItem('credit', optionValue)
				proceed()
			}
		},
		name: {
			id: 'name',
			// preHeadline: 'Please let us know who we are speaking with',
			// headline: "What's your name?",
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return (!questions.value.name.first || !questions.value.name.last) ? false : true
			}),
			type: 'name',
			chat: "",
			first: window.localStorage.getItem("firstname") ? window.localStorage.getItem("firstname") : ref(""),
			last: window.localStorage.getItem("lastname") ? window.localStorage.getItem("lastname") : ref(""),
			submit: () => {
				localStorage.setItem('firstname', questions.value.name.first)
				localStorage.setItem('lastname', questions.value.name.last)
				proceed()

				getLocationData()
			}
		},
		dob: {
			id: 'dob',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return (!questions.value.dob.day || !questions.value.dob.month || !questions.value.dob.year) ? false : true
			}),
			type: 'dob',
			chat: "<p>This will help us find specific solutions for you.</p>",
			day: ref(window.localStorage.getItem("day") ? window.localStorage.getItem("day") : ""),
			month: ref(window.localStorage.getItem("month") ? window.localStorage.getItem("month") : ""),
			year: ref(window.localStorage.getItem("year") ? window.localStorage.getItem("year") : ""),
			dayMaskaObject: reactive({}),
			monthMaskaObject: reactive({}),
			yearMaskaObject: reactive({}),
			submit: () => {
				localStorage.setItem('day', questions.value.dob.day)
				localStorage.setItem('month', questions.value.dob.month)
				localStorage.setItem('year', questions.value.dob.year)

				getArrayData()
				proceed()
			}
		},
		email: {
			id: 'email',
			showHeadlines: false,
			showProgress: true,
			overrideValidation: false,
			validated: computed(() => {
				return !questions.value.email.value ? false : true
			}),
			type: 'email',
			chat: "<p>We'll only send you valuable information related to your needs.</p>",
			value: ref(window.localStorage.getItem("email") ? window.localStorage.getItem("email") : ""),
			sendtoAC: true,
			submit: () => {
				localStorage.setItem('email', questions.value.email.value)
				if (questions.value.email.sendtoAC) {
					let config = {
						method: 'post',
						maxBodyLength: Infinity,
						url: 'https://7nf2fxp2rksxslo4y5nlowihza0vidwh.lambda-url.us-east-2.on.aws/',
						headers: { 
							'Content-Type': 'application/json'
						},
						data : JSON.stringify({
							"email": questions.value.email.value,
							"ip": formData.ip_address,
							"firstname": questions.value.name.first,
							"lastname": questions.value.name.last,
							"debt": questions.value.amount.numberValue,
							"income": questions.value.income.value,
							"faf": "1",
							"seed": "0"
						})
					};

					axios.request(config).then((response) => {
						console.log(response.data);
						let blueShiftEventName = 'fafSignup';
						let config = {
							method: 'post',
							maxBodyLength: Infinity,
							url: 'https://v2lr4cqaqyogu34ykwy7ivjtd40rfbac.lambda-url.us-east-2.on.aws/?email=' + questions.value.email.value + '&eventName=' + blueShiftEventName,
						};

						axios.request(config).then((response) => {
							console.log(response.data);
						}).catch((error) => {
							console.log(error);
						});

						// var d = Math.random();
						// if (d < 0.5) {
							
						// }
					}).catch((error) => {
						console.log(error);
					});
				}
				proceed()

				if (typeof _ddq === 'function') {
					_ddq("email_submit", {
						"email": questions.value.email.value
					});
				}
			}
		},
		phone: {
			id: 'phone',
			showHeadlines: false,
			showProgress: true,
			overrideValidation: false,
			validated: computed(() => {
				if (questions.value.phone.overrideValidation) {
					return false
				}
				return (!questions.value.phone.value) ? false : true
			}),
			type: 'contact',
			chat: '<p style="font-weight: 600; margin-top: 9px;">What happens next?</p><ul><li>Your information will be encrypted using 256 SSL technology.</li><li>You&apos;ll be matched with a debt relief specialist who will call you to discuss the best options to get to a better financial situation.</li></ul>',
			value: ref(window.localStorage.getItem("phone") ? window.localStorage.getItem("phone") : ""),
			maskaObject: reactive({}),
			submit: () => {
				localStorage.setItem('phone', questions.value.phone.value)

				let script = document.createElement('script');
				script.type = 'text/javascript';
				script.async = true;
				script.onload = function(){
					readyToRedirect = true;
				};
				script.src = 'https://pxl.refinition.ai/s/bb147089-086f-4792-885a-d6f91788de18';
				document.getElementsByTagName('head')[0].appendChild(script);

				if (questions.value.email.sendtoAC) {
					let config = {
						method: 'post',
						maxBodyLength: Infinity,
						url: 'https://7nf2fxp2rksxslo4y5nlowihza0vidwh.lambda-url.us-east-2.on.aws/',
						headers: { 
							'Content-Type': 'application/json'
						},
						data : JSON.stringify({
							"email": questions.value.email.value,
							"phone_number": "+1" + questions.value.phone.maskaObject.unmasked,
							"firstname": questions.value.name.first,
							"lastname": questions.value.name.last,
							"ip": formData.ip_address,
							"faf": "1",
							"seed": "0"
						})
					};

					axios.request(config).then((response) => {
						console.log(response.data);
					}).catch((error) => {
						console.log(error);
					});
				}
				if (questionOrder.value.indexOf('description') !== -1) {
					proceed()
				} else {
					submitLead()
				}
			}
		},
		address: {
			id: 'address',
			showHeadlines: false,
			showProgress: true,
			overrideValidation: false,
			validated: computed(() => {
				if (questions.value.address.overrideValidation) {
					return false
				}
				return (
					!questions.value.address.address ||
					!questions.value.address.zip) ? false : true
			}),
			type: 'address',
			chat: "<p>Additional options may apply based on where you live.</p>",
			address: ref(window.localStorage.getItem("address") ? window.localStorage.getItem("address") : ""),
			zip: ref(window.localStorage.getItem("zip") ? window.localStorage.getItem("zip") : ""),
			city: ref(window.localStorage.getItem("city") ? window.localStorage.getItem("city") : ""),
			state: ref(window.localStorage.getItem("state") ? window.localStorage.getItem("state") : ""),
			submit: () => {
				localStorage.setItem('address', questions.value.address.address)
				localStorage.setItem('zip', questions.value.address.zip)
				localStorage.setItem('city', questions.value.address.city)
				localStorage.setItem('state', questions.value.address.state)
				proceed()

				if (questions.value.email.sendtoAC) {
					let config = {
						method: 'post',
						maxBodyLength: Infinity,
						url: 'https://7nf2fxp2rksxslo4y5nlowihza0vidwh.lambda-url.us-east-2.on.aws/',
						headers: { 
							'Content-Type': 'application/json'
						},
						data : JSON.stringify({
							"email": questions.value.email.value,
							"state": questions.value.address.state,
							"zip": questions.value.address.zip,
							"faf": "1",
							"seed": "0"
						})
					};

					axios.request(config).then((response) => {
						console.log(response.data);
					}).catch((error) => {
						console.log(error);
					});
				}
			}
		},
		description: {
			id: 'description',
			headline: 'Briefly describe your debt and why you need relief',
			showHeadlines: false,
			showProgress: true,
			validated: computed(() => {
				return questions.value.description.value.length < 5 ? false : true
			}),
			type: 'openText',
			chat: "<p>Include details such as the amount and type of debt.</p>",
			value: ref(window.localStorage.getItem("description") ?  window.localStorage.getItem("description") : ""),
			submit: () => {
				localStorage.setItem('description', questions.value.description.value)
				submitLead()
			}
		}
	})

	// keep track of the currently displayed question and progress
	let currentProgress = ref(0)

	// used to briefly remove question to ensure smooth transitions
	let removeQuestion = ref(false)

	const getPostKeys = {
		'0': {
			lp_campaign_id: '66280997d0cd2',
			lp_campaign_key: 'zmv8MG2BhD6pJ3QWyLgr'
		},
		'fbr': { // E FB Rev Priority
			lp_campaign_id: '675c501ee686b',
			lp_campaign_key: 'cZpLC7hRz6MxTXP3G9wB'
		},
		'efbn': { // E FB New (97)
			lp_campaign_id: '67a3b5192b975',
			lp_campaign_key: '4mT6pKnbjhx7GcqzY8BP'
		},
		'jgbn': { // J FB New (98)
			lp_campaign_id: '67a3b5ace20cd',
			lp_campaign_key: 'gYvGLX3MVjP7FTQnhWrB'
		},
		'al': { // AppLovin
			lp_campaign_id: '67aa53b479fa3',
			lp_campaign_key: '78QwZJbcWnCG3xYR4tNX'
		},
		'vtv': { // Vibe TV
			lp_campaign_id: '67b6618de0b13',
			lp_campaign_key: 'hNmxFVjBzypqg2RDfJrT'
		},
		'fab': {
			lp_campaign_id: '66b3a10f768ba',
			lp_campaign_key: 'xFVrjpGdmLbWMc9yfYzC'
		},
		'pt': { // Pinterest
			lp_campaign_id: '66f5bf3fb121e',
			lp_campaign_key: 'zpthk6MjN79JWLyBgPYb'
		},
		'5': { // BMM mail
			lp_campaign_id: '66c64d873df4b',
			lp_campaign_key: 'LmJgtXc67h83V49MdGxK'
		},
		'12': { // LGD mail - New Everflow
			lp_campaign_id: '66c652cf8f0ac',
			lp_campaign_key: 'DNj2Vw6zHXnvFRWPrqyL'
		},
		'42': { // Zeta
			lp_campaign_id: '66e9b09546155',
			lp_campaign_key: 'FTGWxjnqw6ZyDV7Jcbtg'
		},
		'43': { // Rooftop
			lp_campaign_id: '66e9b0666cd5f',
			lp_campaign_key: 'j8R3KnwyB2p9XfYqhzbJ'
		},
		'15': { // Live Oak
			lp_campaign_id: '66e8b61c67d0c',
			lp_campaign_key: 'mMrNwZfhyYJgG6p7Tc9R',
		},
		'sa': { // Sniper Ads
			lp_campaign_id: '66e8b88869d10',
			lp_campaign_key: 'kZz72rfbKpD4HVgMRyvP'
		},
		'sctt': { // Scintilla TikTok
			lp_campaign_id: '675c66c0a8d22',
			lp_campaign_key: 'QDwTkdXtYRPN9fZMxzLG',
			everflow_aff_id: '21',
			everflow_offer_id: 1
		},
		'sc': { // Scintilla TikTok 2
			lp_campaign_id: '675c6747d53ea',
			lp_campaign_key: '6CHdQWqbLMDwryPNKXpY',
			everflow_aff_id: '21',
			everflow_offer_id: 2
		},
		'cvtt': { // Click Ventures TikTok
			lp_campaign_id: '675c6519d5935',
			lp_campaign_key: 'KvVHNjzgX7mQB8T2f64G',
			everflow_aff_id: '6',
			everflow_offer_id: 1
		},
		'cvk': { // Click Ventures 2
			lp_campaign_id: '675c6616e783d',
			lp_campaign_key: 'FPLYqJQdxG42VbCXzMtc',
			everflow_aff_id: '7',
			everflow_offer_id: 1
		},
		'cvfb': { // Click Ventures FB
			lp_campaign_id: '675c65a4ca629',
			lp_campaign_key: '8mdyHxLMwRVWZftXbgv6',
			everflow_aff_id: '6',
			everflow_offer_id: 2
		},
		'env': { // Elise non verified
			lp_campaign_id: '67020f8ebce46',
			lp_campaign_key: 'bBP3CMgwVdY8k7ZLxcRp'
		},
		'sg': { // Salius Gabe
			lp_campaign_id: '675c67a68f3b6',
			lp_campaign_key: 'nxXrfpYV9c7GZ83mtT6z',
			// click_campaign_id: '35',
			everflow_aff_id: '19',
			everflow_offer_id: 1
		},
		'sm': { // Salius Martin
			lp_campaign_id: '67864d4788000',
			lp_campaign_key: 'dqVMDnbvF64typwZzK3f',
			everflow_aff_id: '27',
			everflow_offer_id: 1
		},
		'sp': { // Salius Peduzzi
			lp_campaign_id: '675c745dc067c',
			lp_campaign_key: 'mTjRCQLK8kz7BGNt9cVn',
			everflow_aff_id: '20',
			everflow_offer_id: 1
		},
		'gfb': { // Griffen FB
			lp_campaign_id: '675c68061746d',
			lp_campaign_key: 'gwVPp8WkdJchnjXGFHmf',
			everflow_aff_id: '22',
			everflow_offer_id: 1
		},
		'gnb': { // Griffen Newsbreak
			lp_campaign_id: '67d35746a6bed',
			lp_campaign_key: 'D4wtYcHdxRLCJnrXvzMy',
			everflow_aff_id: '22',
			everflow_offer_id: 3
		},
		'gyt': { // Griffen YT
			lp_campaign_id: '67106a1969a29',
			lp_campaign_key: '8xTGfKLDnWCrg62Q4w7z',
			click_campaign_id: '38',
			everflow_aff_id: '22',
			everflow_offer_id: 2
		},
		'll': { // James Legacy Leads
			lp_campaign_id: '6774efb76d146',
			lp_campaign_key: 'Hdm9z4gBkjYWJGvRM7DT',
			everflow_aff_id: '14',
			everflow_offer_id: 1
		},
		// 'mc': { // Fahmey Moname Consulting
		// 	lp_campaign_id: '6711b40e992de',
		// 	lp_campaign_key: '7jWmFw9y3gPbxQ2K6GXH',
		// 	click_campaign_id: '41',
		// 	everflow_aff_id: '49',
		// 	everflow_offer_id: 1
		// },
		'ssc': { // Scintilla Snapchat
			lp_campaign_id: '675c6666a1257',
			lp_campaign_key: 'xDNZg3XwWzK6CrYcRQV2',
			everflow_aff_id: '21',
			everflow_offer_id: 3
		},
		'sct': { // Scintilla Twitter
			lp_campaign_id: '678e7f7f9323e',
			lp_campaign_key: 'XtjVQxJv7GRC3FL2y6MD',
			everflow_aff_id: '21',
			everflow_offer_id: 4
		},
		'cy': { // CY (Carter York)
			lp_campaign_id: '67337038cdb3e',
			lp_campaign_key: 'kHYmgWpnxcr6NDtZd9KF',
			click_campaign_id: '50',
			everflow_aff_id: '8',
			everflow_offer_id: 1
		},
		'efb': { // Elise FB 2
			lp_campaign_id: '6723e11fa54ad',
			lp_campaign_key: 'KRJZkzB3nVFYtfXPQypD'
		},
		'jfb': { // Justine FB 2
			lp_campaign_id: '6723e1327e697',
			lp_campaign_key: 'qtFHwCWxZKjRNcDgrMY4'
		},
		'jfbn': { // Justine FB 3
			lp_campaign_id: '67645bd06a5ad',
			lp_campaign_key: 'VDPgbBnYWHTtJKMq92jr'
		},
		'nfb': { // Nico FB
			lp_campaign_id: '672e356d32995',
			lp_campaign_key: 'ZyxBC3JmYH7c9jDqTp2K'
		},
		'nb': { // Nico Newsbreak
			lp_campaign_id: '678ebedcbb034',
			lp_campaign_key: 'YTMyj3V7bcCrhQFG9dgw'
		},
		'ndb': { // NDB Offer Wall
			lp_campaign_id: '6734d9d4ba383',
			lp_campaign_key: '8x2qmPLXyhKBZYRbvNjk',
			everflow_aff_id: '16',
			everflow_offer_id: 1
		},
		'sn': { // Salius Nick
			lp_campaign_id: '675c754725e9e',
			lp_campaign_key: 'yFPj3Dvx7K4tc9qbfZwg',
			everflow_aff_id: '26',
			everflow_offer_id: 1
		},
		'kj': { // Koji
			lp_campaign_id: '675c75a35c1dd',
			lp_campaign_key: '26xpnV98rGhNdMvWgPkf',
			everflow_aff_id: '10',
			everflow_offer_id: 1
		},
		'tb': { // Taboola
			lp_campaign_id: '673cc2993122a',
			lp_campaign_key: 'v2NGt7chYHVRbMnXkprB',
			everflow_aff_id: '18',
			everflow_offer_id: 1
		},
		'bfb': { // Blake FB
			lp_campaign_id: '673cc73c0fed4',
			lp_campaign_key: 'RzDvCJgpnW7c2MLyb6TP'
		},
		're': { // Ridgeville Entities (Arbmaniac)
			lp_campaign_id: '673f8c1006991',
			lp_campaign_key: 'rgFQTC8DnfKkzwytXpLM',
			everflow_aff_id: '17',
			everflow_offer_id: 1
		},
		'pzfb': { // PZ FB2
			lp_campaign_id: '674d39293e50f',
			lp_campaign_key: '28w4k3PnhJpHYR7rxmZc',
			everflow_aff_id: '13',
			everflow_offer_id: 2
		},
		'lgfb': { // PZ FB1
			lp_campaign_id: '674d390c84945',
			lp_campaign_key: 'qftd4zLPb37HhnWGMyBk',
			everflow_aff_id: '13',
			everflow_offer_id: 1
		},
		'lgg': { // PZ Google
			lp_campaign_id: '674d39421d41e',
			lp_campaign_key: 'Q34nxH2fYKLvNXzp6cFr',
			everflow_aff_id: '13',
			everflow_offer_id: 3
		},
		'tk': { // 2 Kings Arvin
			lp_campaign_id: '675c6979df72a',
			lp_campaign_key: '6CdRxgr7vbXMTHNyDnj9',
			everflow_aff_id: '4',
			everflow_offer_id: 1
		},
		// 'vi': { // Vinocose
		// 	lp_campaign_id: '674fe56c3cde4',
		// 	lp_campaign_key: 'XTcWJd26DCb3P87wzptK',
		// 	click_campaign_id: '64',
		// 	everflow_aff_id: '57',
		// 	everflow_offer_id: 1
		// },
		'bs': { // Email
			lp_campaign_id: '6751d5f85e6de',
			lp_campaign_key: '4pcLCg29wvWB8NDjH6Rr',
			everflow_aff_id: '9',
			everflow_offer_id: 1
		},
		'faa': { // PZ FB3 - First Advantage Associates
			lp_campaign_id: '67570dae4a92b',
			lp_campaign_key: 'hpfFq9MmHj2QgXrZkx4N',
			everflow_aff_id: '13',
			everflow_offer_id: 4
		},
		'kyt': { // Kyle YT
			lp_campaign_id: '6771ac4258a97',
			lp_campaign_key: 'WV2M8Z6kFK9HwxhPcTmb',
			everflow_aff_id: '11',
			everflow_offer_id: 1
		},
		'rsg': { // Scibelli Google
			lp_campaign_id: '67c70e4461098',
			lp_campaign_key: 'jvKPXGy96WrYd2BJMCpm',
			click_campaign_id: '109',
			everflow_aff_id: '23',
			everflow_offer_id: 1
		},
		'23': { // Scibelli YT
			lp_campaign_id: '67c70e4461098',
			lp_campaign_key: 'jvKPXGy96WrYd2BJMCpm',
			click_campaign_id: '109',
			everflow_aff_id: '23',
			everflow_offer_id: 1
		},
		'sfa': { // Spataro
			lp_campaign_id: '67882a1edb380',
			lp_campaign_key: '6Cd4Bwx9Z8WvgNrk2Mmj',
			everflow_aff_id: '28',
			everflow_offer_id: 1
		},
		'29': { // MassConnect Email
			lp_campaign_id: '67aa4b1f6f709',
			lp_campaign_key: 'ghnbYdG23BJ6QVFwHcK4',
			everflow_aff_id: '29',
			everflow_offer_id: 1
		},
		'291': { // MassConnect Email
			lp_campaign_id: '67aa4b1f6f709',
			lp_campaign_key: 'ghnbYdG23BJ6QVFwHcK4',
		},
		'292': { // Mass Connect Path
			lp_campaign_id: '67aa4b2ebd7dc',
			lp_campaign_key: 'r7RZQgfDnLjKMTwFWdCk',
		},
		'mg': { // Media Gravy
			lp_campaign_id: '67aa4c79296b0',
			lp_campaign_key: 'cjzpMQmrBR2bqfkPGtgy',
			everflow_aff_id: '30',
			everflow_offer_id: 1
		},
		'311': { // Z3 Native
			lp_campaign_id: '67ab7f182e980',
			lp_campaign_key: '4GTBmZkcW29QKLxFbdhC'
		},
		'jo': { // Jeanette Organic
			lp_campaign_id: '67b3b43c91a4f',
			lp_campaign_key: 'y9Z6ndxgwhf24QMrkG3c'
		},
		'io': { // Internal Organic
			lp_campaign_id: '67b3b4af0a4ba',
			lp_campaign_key: 'RFnb2fqWyZ48hwGJMHCz'
		},
		'32': { // Flex Email
			lp_campaign_id: '67b4b6f357f0f',
			lp_campaign_key: '8FPHzxQnwC4jDvGdMbqg',
		},
		'34': { // Banner Edge
			lp_campaign_id: '67cf6566473bc',
			lp_campaign_key: 'n9jyJXKH8q7wcbLT2Rx6',
		},
		'scal': { // Scalability
			lp_campaign_id: '67d1b9d401ad5',
			lp_campaign_key: 'PtrbdRVjHQZgmkwvLNzx',
			everflow_aff_id: '35',
			everflow_offer_id: 1
		},
		'der': { // Definitive Edge Reddit
			lp_campaign_id: '67e7132c07beb',
			lp_campaign_key: 'TzdVvxnrwqPFhRcW987N',
			everflow_aff_id: '36',
			everflow_offer_id: 1
		},
		'dett': { // Definitive Edge TikTok
			lp_campaign_id: '67e713f122b79',
			lp_campaign_key: 'kG2QbJyxVDm9jWBnTqPL',
			everflow_aff_id: '36',
			everflow_offer_id: 2
		},
		'defb': { // Definitive Edge Facebook
			lp_campaign_id: '67e7141261fc1',
			lp_campaign_key: '9xKVNZFywrMDt6Gj4Hkc',
			everflow_aff_id: '36',
			everflow_offer_id: 3
		}
	}
	
	// storing some general form data that we'll need upon submission
	const params = new URLSearchParams(window.location.search)
	const formData = {
		// lp_test: 1,
		ip_address: "",
		aff_id: params.has('aid') ? params.get('aid') : getCookie('aff_id') ? getCookie('aff_id') : "0",
		lp_s1: params.has('s1') ? params.get('s1') : params.has('sub1') ? params.get('sub1') : "",
		lp_s2: params.has('s2') ? params.get('s2') : params.has('sub2') ? params.get('sub2') : "",
		lp_s3: params.has('s3') ? params.get('s3') : params.has('sub3') ? params.get('sub3') : "",
		lp_s4: params.has('s4') ? params.get('s4') : params.has('sub4') ? params.get('sub4') : "",
		lp_s5: params.has('s5') ? params.get('s5') : "",
		user_agent: navigator.userAgent,
		lp_response: "json",
		landing_page: "https://firstadvantage.io/app/",
		array_requested: params.get('sa') === '1' ? false : true
	}
	if (window.location.hostname === 'go.farelief.com') {
		formData.aff_id = 'jo';
	} else if (window.location.hostname === 'www.farelief.com') {
		formData.aff_id = 'io';
	} else if (window.location.hostname === 'farelief.com') {
		formData.aff_id = 'io';
	} else if (window.location.hostname === 'fadebtrelief.com') {
		formData.aff_id = 'vtv';
	} else if (window.location.hostname === 'www.fadebtrelief.com') {
		formData.aff_id = 'vtv';
	}
	const directLinkAffs = ['29', '31']
	if (directLinkAffs.includes(formData.aff_id)) {
		formData.aff_id = formData.aff_id + (params.has('oid') ? params.get('oid') : "")
	}
	console.log(formData)
	if (!getPostKeys[formData.aff_id]) {
		formData.aff_id = "0"
	}
	if (formData.aff_id !== "0") {
		setCookie('aff_id',formData.aff_id,7);
	}

	if (!formData.array_requested) {
		questionOrder.value = [
			'amount',
			'name',
			'email',
			'address',
			'dob',
			'phone'
		]
	}

	// if qualified, we'll try to grab location data by IP
	const getLocationData = () => {
		axios.get("https://fragrant-fog-f988.ryan-v-0b2.workers.dev/")
			.then(function (response) {
				let userIP = response.data.ip;

				formData.ip_address = userIP;

				// Set backup location data
				backupCity = response.data.city || "Unknown";
				backupState = response.data.region || "Unknown";
				backupZip = response.data.zip || "Unknown";
			})
			.catch(function (error) {
				console.error("Error fetching location data:", error);
			});
	};

	const getArrayData = () => {
		if (!formData.array_requested) {
			return
		}
		let config = {
			method: 'post',
			url: 'https://l4zw6nqbv5by2jukwdq6yz46py0wqjor.lambda-url.us-east-2.on.aws/',
			headers: {
				'Content-Type': 'application/json'
			},
			data: {
				firstName: questions.value.name.first,
				lastName: questions.value.name.last,
				address: {
					street: questions.value.address.address,
					city: questions.value.address.city ? questions.value.address.city : backupCity,
					state: questions.value.address.state ? questions.value.address.state : backupState,
					zip: questions.value.address.zip
				},
				dob: questions.value.dob.year + '-' + questions.value.dob.month + '-' + questions.value.dob.day,
				userConsent: {
					ipAddress: formData.ip_address,
					timestamp: new Date().toISOString(),
					url: formData.landing_page
				}
			}
		}
		axios.request(config).then((response) => {
			console.log(response.data);

			if (response.data !== null && typeof response.data === 'object') { // if the response is an object
				// remove open description if it exists
				questionOrder.value = [
					'amount',
					'income',
					'name',
					'email',
					'address',
					'dob',
					'phone'
				]
				if (response.data.hasOwnProperty('results')) { // if the object contains results
					for (var key in response.data.results) { // for each key in the results object
						if (response.data.results.hasOwnProperty(key) && arrayData.hasOwnProperty(key)) { // if arrayData has the same key
							var result = response.data.results[key] // store the data object to simplify
							for (var resultKey in result) {
								if (result.hasOwnProperty(resultKey)) {
									if (result[resultKey]) {
										arrayData[key] = resultKey
									}
								}
							}
						}
					}
				}
			} else {
				// add open description question
				questionOrder.value = [
					'amount',
					'income',
					'name',
					'email',
					'address',
					'dob',
					'phone',
					// 'realEstate',
					// 'assets',
					// 'description'
				]
			}
		}).catch((error) => {
			console.log(error);
		});
	}

	// keep track of current progress percentage
	const progressPercent = computed(() => {
		return Math.round(((currentProgress.value + 1) / (questionOrder.value.length + 1)) * 100)
	})
	// store the currently active question object
	const currentQuestion = computed(() => {
		return questions.value[questionOrder.value[currentProgress.value]]
	})
	// boolean on whether or not we already stored location data from IP
	const hasLocationData = computed(() => {
		return questions.value.address.zip && questions.value.address.city && questions.value.address.state ? true : false
	})

	// is the transition moving forwards or backwards 1 question
	const transitionDirection = ref("forwards")
	// move forward 1 question
	function proceed() {
		gtag("event", currentQuestion.value.id);
		transitionDirection.value = "forwards"
		removeQuestion.value = true

		window.setTimeout(function() {
			currentProgress.value++
			removeQuestion.value = false
			document.getElementById('header').scrollIntoView();

			// lets use hashing for state tracking
			history.pushState({currentProgress: currentProgress.value}, "", "#" + currentQuestion.value.id);

			localStorage.setItem('questionOrder', JSON.stringify(questionOrder.value))
		}, 10)
	}
	window.addEventListener("popstate", (event) => {
		if (event.state === null) {
			window.history.go(-1)
		} else {
			console.log(event.state)
			transitionDirection.value = currentProgress.value < event.state.currentProgress ? "forwards" : "backwards"
			removeQuestion.value = true

			window.setTimeout(function() {
				currentProgress.value = event.state.currentProgress
				removeQuestion.value = false
				document.getElementById('header').scrollIntoView();
				localStorage.setItem('questionOrder', JSON.stringify(questionOrder.value))
			}, 10)
		}
	});
	// move backwards 1 question
	function goBack() {
		history.back()
	}
	// hide or show the back button
	const backButtonVisible = ref(true)
	const hideBackButton = function() {
		backButtonVisible.value = false
	}
	const showBackButton = function() {
		backButtonVisible.value = true
	}
	// hide or show the spinner during submission
	const showSpinner = ref(false)

	const displayThankYou = ref(false)

	const advertiser = ref("0")

	// attempt lead submission
	const submitLead = function() {
		backButtonVisible.value = false
		showSpinner.value = true
		document.getElementById('header').scrollIntoView()
		const dt = new Date()

		let USDollar = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		});

		// check if array debt matches lead response
		var arrayDebtResponse = arrayData.total_unsecured_debt;
		var creditScore = arrayData.credit_score;
		var collections = arrayData.collections;
		var delinquencies = arrayData.delinquencies;

		var noloArrayString = "";

		var verified_debt = 0;
		if (arrayDebtResponse) {
			var lowEnd = 0;
			var highEnd = 0;
			var debtNumber;

			if (arrayDebtResponse.indexOf('over') !== -1) {
				// debt over number (+5k)
				debtNumber = arrayDebtResponse.match(/\d.*/);
				debtNumber = Number(debtNumber[0]);
				console.log(debtNumber);
				lowEnd = debtNumber;
				highEnd = debtNumber + 5000;
				verified_debt = (Number(lowEnd)+Number(highEnd))/2;
				noloArrayString += `Unsecured debt is over ${USDollar.format(lowEnd)}. `;
			} else if (arrayDebtResponse.indexOf('under') !== -1) {
				// debt under number (-5k)
				debtNumber = arrayDebtResponse.match(/\d.*/);
				debtNumber = Number(debtNumber[0]);
				console.log(debtNumber);
				lowEnd = debtNumber - 100;
				highEnd = debtNumber;
				verified_debt = (Number(lowEnd)+Number(highEnd))/2;
				noloArrayString += `Unsecured debt is under ${USDollar.format(highEnd)}. `;
			} else {
				// debt is between numbers
				debtNumber = arrayDebtResponse.match(/\d.*/);
				console.log(debtNumber);
				lowEnd = debtNumber[0].split('_')[0];
				highEnd = debtNumber[0].split('_')[1];
				verified_debt = Number(highEnd);
				noloArrayString += `Unsecured debt is between ${USDollar.format(lowEnd)} and ${USDollar.format(highEnd)}. `;
			}
		}

		let openDescription = questions.value.description.value
		if (!openDescription || openDescription === "") {
			// openDescription = noloArrayString
		}
		const data = {
			...formData,
			'lp_campaign_id': getPostKeys[formData.aff_id].lp_campaign_id,
			'lp_campaign_key': getPostKeys[formData.aff_id].lp_campaign_key,
			'pd_company_id': getPostKeys[formData.aff_id].pd_company_id,
			'address': questions.value.address.address,
			'zip_code': questions.value.address.zip ? questions.value.address.zip : backupZip,
			'city': questions.value.address.city ? questions.value.address.city : backupCity,
			'state': questions.value.address.state ? questions.value.address.state : backupState,
			'email_address': questions.value.email.value,
			'first_name': questions.value.name.first,
			'last_name': questions.value.name.last,
			'phone_home': questions.value.phone.maskaObject.unmasked,
			'dob': questions.value.dob.month + '/' + questions.value.dob.day + '/' + questions.value.dob.year,
			'credit': questions.value.credit.value,
			'income': questions.value.income.value,
			'employed': questions.value.employed.value,
			'debt_amount': questions.value.amount.numberValue,
			'jornaya_lead_id': document.getElementById('leadid_token').value,
			'trusted_form_id': document.getElementsByName('xxTrustedFormToken')[0] ? document.getElementsByName('xxTrustedFormToken')[0].value : '',
			'array_collections': arrayData.collections,
			'array_credit': arrayData.credit_score,
			'array_delinquencies': arrayData.delinquencies,
			'array_debt': arrayData.total_unsecured_debt,
			'verified_debt': verified_debt,
			'description': openDescription,
			'assets': questions.value.assets.value ? questions.value.assets.value : '$50-$500k',
			'own_real_estate': questions.value.realEstate.value ? questions.value.realEstate.value : false,
			'split_test_variant': variantName
		}
		if (LpRequestId) {
			data.lp_request_id = LpRequestId
		}
		const postData = qs.stringify(data);
		var config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: 'https://bluemodomedia.leadspediatrack.com/post.do',
			headers: { 
				'Content-Type': 'application/x-www-form-urlencoded',
			},
			data : postData
		};

		// send lead, store lead id in lpLeadId
		axios(config).then(function(response) {
			console.log(response)
			console.log(response.data)
			const result = response.data.result
			const msg = response.data.msg
			lpLeadId = response.data.lead_id
			const revenue = Number(response.data.price)
			let sold = false

			if (result === 'success' && msg === 'Lead Accepted') {
				sold = true
				const clients = response.data.clients
				if (clients) {
					for (var i = 0; i < clients.length; i++) {
						if (clients[i].client.success === "true") {
							advertiser.value = String(clients[i].client.advertiserID);
						}
					}
				}

				// fire LP Pixel if request ID exists (for bucket setup)
				if (LpRequestId) {
					axios.post('https://2u76bw6o7t3dpvhg45iwfoykvq0ewsgs.lambda-url.us-east-2.on.aws/&request_id=' + LpRequestId).then(function(pixelResponse) {})
				}

				// fire TT conversions
				if (revenue >= 45) {
					if (formData.aff_id === 'cvtt') {
						ttq.track('SubmitForm');
					} else if (formData.aff_id === 'sctt') {
						// ttq.track('CompleteRegistration'); 
						// ttq.track('SubmitForm');
					} else if (typeof vbpx === 'function') {
						vbpx('event', 'lead');
					}
				}
			} else {
				// if (!formData.array_requested && msg !== 'Duplicate' && questionOrder.value.indexOf('description') === -1) {
				// 	// push additional questions
				// 	questionOrder.value.push('income', 'realEstate', 'assets', 'description')
				// 	backButtonVisible.value = false
				// 	showSpinner.value = false
				// 	proceed()
				// 	document.getElementById('header').scrollIntoView()
				// 	return
				// }
			}

			if (advertiserMapping[advertiser.value] && questions.value.email.sendtoAC && revenue > 0) {
				let config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'https://7nf2fxp2rksxslo4y5nlowihza0vidwh.lambda-url.us-east-2.on.aws/',
					headers: { 
						'Content-Type': 'application/json'
					},
					data : JSON.stringify({
						"email": questions.value.email.value,
						"buyer": advertiserMapping[advertiser.value].buyer,
						"buyerPhone": advertiserMapping[advertiser.value].did,
						"faf": "1",
						"seed": "0"
					})
				};

				axios.request(config).then((response) => {
					console.log(response.data);
				}).catch((error) => {
					console.log(error);
				});

				let eventConfig = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'https://v2lr4cqaqyogu34ykwy7ivjtd40rfbac.lambda-url.us-east-2.on.aws/?email=' + questions.value.email.value + '&eventName=triggerBuyerSequence',
				};

				axios.request(eventConfig).then((response) => {
					console.log(response.data);
				}).catch((error) => {
					console.log(error);
				});

				if (advertiserMapping[advertiser.value].did !== null) {
					
				}
			}

			if (revenue < 2 && result.toLowerCase() !== 'duplicate') {
				// this sends leads to the call center (readymode). Through waypoint for blacklistalliance
				let CSconfig = {
					method: 'get',
					maxBodyLength: Infinity,
					url: 'https://bluemodo.waypointsoftware.com/capture2.php?xAuthentication=8ac67eabf731769da3eed05640fb1c87&firstname=' + questions.value.name.first + '&lastname=' + questions.value.name.last + '&phone1=1' + questions.value.phone.maskaObject.unmasked + '&city=' + questions.value.address.city + '&state=' + questions.value.address.state + '&zip=' + questions.value.address.zip + '&email=' + questions.value.email.value,
					headers: { }
				};

				axios.request(CSconfig).then((CSresponse) => {
					// console.log(CSresponse);
				}).catch((CSerror) => {
					// console.log(CSerror);
				});
			}

			let redirected = false
			if (response.data.redirect_url) {
				if (response.data.redirect_url.substring(0, 8) === 'https://') {
					if (readyToRedirect) {
						window.location.href = response.data.redirect_url.replace('\\u0026', '&')
					} else {
						let redirectCheck = window.setInterval(function() {
							if (readyToRedirect) {
								clearInterval(redirectCheck)
								window.location.href = response.data.redirect_url.replace('\\u0026', '&')
							}
						}, 300)
					}
					redirected = true
				}
			} else {
				if (advertiserMapping[advertiser.value] && revenue > 1) {
					displayThankYou.value = true
				} else {
					console.log('trigger redirect')
					sendToEzsend(data)
					// axios.get('https://bluemodo.waypointsoftware.com/capture3.php?xAuthentication=505fab381163fd6997cb9424ccafeb48&phone1=' + data.phone_home + '&firstname=' + data.first_name + '&lastname=' + data.last_name + '&email=' + data.email_address + '&zip=' + data.zip_code + '&trustedform=' + data.trusted_form_id + '&xleadid=' + lpLeadId + '&jornaya=' + data.jornaya_lead_id + '&state=' + data.state + '&ipaddress=' + data.ip_address + '&landing_page_url=' + data.landing_page);
					// if (Math.random() < 0.5) {
					// 	window.location.href = "https://firstadvantage.io/programs/"
					// } else {
					// 	window.location.href = "https://firstadvantage.io/programs/save.html"
					// }

					let redirectUrlCustom = "https://firstadvantage.io/programs/save.html?vd=" + data.verified_debt.toString()
					if (data.verified_debt === 9949) {
						redirectUrlCustom = "https://www.ab92trk.com/2X2ZMNN/45TJ3T/?sub1=fa1&sub2=" + (data.aff_id || '') + "&first=" + (data.first_name || '') + "&last=" + (data.last_name || '') + "&phone=" + (questions.value.phone.maskaObject.unmasked || '') + "&email=" + (data.email_address || '') + "&state=" + (data.state || '');
					} else if (data.verified_debt === 0 && revenue < 1) {
						redirectUrlCustom = "https://www.ab92trk.com/2X2ZMNN/45TJ3T/?sub1=fa3&sub2=" + (data.aff_id || '') + "&first=" + (data.first_name || '') + "&last=" + (data.last_name || '') + "&phone=" + (questions.value.phone.maskaObject.unmasked || '') + "&email=" + (data.email_address || '') + "&state=" + (data.state || '');
					} else if (data.verified_debt > 15000 && revenue < 1) {
						redirectUrlCustom = "https://www.ab92trk.com/2X2ZMNN/45TJ3T/?sub1=fa4&sub2=" + (data.aff_id || '') + "&first=" + (data.first_name || '') + "&last=" + (data.last_name || '') + "&phone=" + (questions.value.phone.maskaObject.unmasked || '') + "&email=" + (data.email_address || '') + "&state=" + (data.state || '');
					}
					console.log(redirectUrlCustom)
					console.log(redirected)
					if (!redirected) {
						console.log(readyToRedirect)
						if (readyToRedirect) {
							window.location.href = redirectUrlCustom
						} else {
							let redirectCheck = window.setInterval(function() {
								if (readyToRedirect) {
									clearInterval(redirectCheck)
									window.location.href = redirectUrlCustom
								}
							}, 300)
						}
					}
				}
			}
			
		}).catch(function(error) {
			console.log(error)
		})

		fbq('track', 'CompleteRegistration');
		gtag("event", "address");
	}

	const redirectUrl = computed(() => {
		let ac = acContactId ? 'Yes' : 'No'
		return "https://firstadvantage.io/?lid=" + lpLeadId + "&aid=" + formData.aff_id + "&ac=" + ac
	})

	onMounted(() => {
		// trusted form
		(function() {
            var field = 'xxTrustedFormCertUrl';
            var provideReferrer = false;
            var invertFieldSensitivity = false;
            var tf = document.createElement('script');
            tf.type = 'text/javascript'; tf.async = true; tf.defer = true;
            tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
              '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s); }
        )();

		// meta pixel
		!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '8743882179013171');
        fbq('track', 'PageView');

		if (getPostKeys[formData.aff_id].hasOwnProperty('everflow_aff_id')) {
			let EfAffId = getPostKeys[formData.aff_id]['everflow_aff_id']
			let EFscript = document.createElement('script');
			EFscript.type = 'text/javascript';
			EFscript.async = true;
			EFscript.onload = function(){
				EF.click({
					offer_id: getPostKeys[formData.aff_id]['everflow_offer_id'] ? getPostKeys[formData.aff_id]['everflow_offer_id'] : 320, // Required. The offer id
					affiliate_id: EfAffId, //Required. The affiliate id

					// Optional. Sub placement values.
					sub1: EF.urlParameter('s1'),
					sub2: EF.urlParameter('s2'),
					sub3: EF.urlParameter('s3'),
					sub4: EF.urlParameter('s4'),
					sub5: EF.urlParameter('s5'),
				}).then((transactionId) => {
					formData.lp_s5 = transactionId;

					// track clicks if this is a bucket setup campaign
					if (getPostKeys[formData.aff_id].hasOwnProperty('click_campaign_id')) {
						console.log('click pixel')
						console.log(getPostKeys[formData.aff_id]['click_campaign_id'])
						let LpClickScript = document.createElement('script');
						LpClickScript.type = 'text/javascript';
						LpClickScript.async = true;
						LpClickScript.onload = function(){
							LpRequestId = lp_request_id
							console.log(LpRequestId)
						};
						LpClickScript.src = 'https://bluemodomedia.leadspediatrack.com/?c=' + getPostKeys[formData.aff_id]['click_campaign_id'] + '&t=p&s5=' + formData.lp_s5 + '&s2=' + formData.lp_s2;
						document.getElementsByTagName('head')[0].appendChild(LpClickScript);
					}
				});
			};
			EFscript.src = 'https://www.fpplk8trk.com/scripts/sdk/everflow.js';
			document.getElementsByTagName('head')[0].appendChild(EFscript);
		} else if (getPostKeys[formData.aff_id].hasOwnProperty('click_campaign_id')) {
			console.log('click pixel')
			console.log(formData.lp_s5)
			console.log(getPostKeys[formData.aff_id]['click_campaign_id'])
			let LpClickScript = document.createElement('script');
			LpClickScript.type = 'text/javascript';
			LpClickScript.async = true;
			LpClickScript.onload = function(){
				if (lp_request_id) {
					LpRequestId = lp_request_id
					console.log(LpRequestId)
				}
			};
			LpClickScript.src = 'https://bluemodomedia.leadspediatrack.com/?c=' + getPostKeys[formData.aff_id]['click_campaign_id'] + '&t=p&s5=' + formData.lp_s5 + '&s2=' + formData.lp_s2;
			document.getElementsByTagName('head')[0].appendChild(LpClickScript);
		}

		if (formData.aff_id === 'cvtt') {
			// load TikTok
			!function (w, d, t) {
			w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie","holdConsent","revokeConsent","grantConsent"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(
			var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var r="https://analytics.tiktok.com/i18n/pixel/events.js",o=n&&n.partner;ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=r,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script")
			;n.type="text/javascript",n.async=!0,n.src=r+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
			}(window, document, 'ttq');
			ttq.load('CRU9FK3C77U3OBOND6SG');
			ttq.page();
		} else if (formData.aff_id === 'vtv') {
			!function(v,i,b,e,c,o){if(!v[c]){var s=v[c]=function(){s.process?s.process.apply(s,arguments):s.queue.push(arguments)};s.queue=[],s.b=1*new Date;var t=i.createElement(b);t.async=!0,t.src=e;var n=i.getElementsByTagName(b)[0];n.parentNode.insertBefore(t,n)}}(window,document,"script","https://s.vibe.co/vbpx.js","vbpx");
			vbpx('init','MNkYik');
			vbpx('event', 'page_view');
		}

		if (window.location.hash) {
			// start by checking for stored question order
			let updatedQuestionOrder = localStorage.getItem('questionOrder')
			if (!updatedQuestionOrder) {
				return
			}
			questionOrder.value = JSON.parse(updatedQuestionOrder)

			// start by checking which question we're on
			let updatedProgress = questionOrder.value.indexOf(window.location.hash.replace('#',''))
			console.log(updatedProgress)

			if (updatedProgress !== -1) {
				// we need to make sure we have all the data up to this point.
				for (var i = 0; i<updatedProgress; i++) {
					// if the question is contact we'll need to handle the 2 fields individually
					if (questions.value[questionOrder.value[i]].id === 'name') {
						if (questions.value.name.first === null ||
							questions.value.name.last === null) {
							updatedProgress = i
							break
						}
					} else { // otherwise we can just check to make sure there is a value
						if (questions.value[questionOrder.value[i]].value === null || questions.value[questionOrder.value[i]].value === "") {
							updatedProgress = i
							break
						}
					}
					history.pushState({currentProgress: i}, "", "#" + questions.value[questionOrder.value[i]].id);
				}
				// Now move forward to the correct question
				currentProgress.value = updatedProgress

				if (questions.value.name.first !== null && questions.value.name.first !== "") {
					getLocationData()
				}

				if (questions.value.dob.day !== null) {
					// wait for IP address
					if (formData.ip_address !== "") {
						getArrayData()
					} else {
						let waitForIp = window.setInterval(function() {
							if (formData.ip_address !== "") {
								clearInterval(waitForIp)
								getArrayData()
							}
						}, 500)
					}
					
				}
			}
		}

		history.pushState({currentProgress: currentProgress.value}, "", "#" + currentQuestion.value.id);

		// wait for gtag
		const waitForGtag = window.setInterval(function() {
			console.log(gtag);
			if (gtag) {
				clearInterval(waitForGtag);
				gtag('event', 'experience_impression', {
					exp_variant_string: variantName
				});
				gtag("event", "form-init");
			}
		}, 500);

		(function (p, i, x, e, l) {
			if (typeof window !== undefined) {
				window._dojoPixel = {};
				window._dojoPixel.events_queue =  window._dojoPixel.events_queue || [];
				window._ddq = function () {
					window._dojoPixel.events_queue.push(arguments);
				};
				var f = p.getElementsByTagName(i)[0],
					k = p.createElement(i);
				k.async = 1;
				k.src = "https://pixel.clickslogistics.com/load.js?id=678eccc254895d060e28cbb7";
				f.parentNode.insertBefore(k, f);
			}
		})(document, "script");
		_ddq("PIXEL_INIT", {
			// you can add your own custom data here...
		});
		_ddq("PAGE_VIEW");

		if (formData.aff_id === "yt") {
			const rtscript = document.createElement('script');
			rtscript.type = 'text/javascript';
			rtscript.onload = function(){
				// give it a second to make sure the click ID is stored
				window.setTimeout(function() {
					formData.lp_s5 = getCookie('rtkclickid-store')
				}, 1000)
			};
			rtscript.src = "https://path.firstadvantage.io/track.js?rtkcmpid=67ed63f842734de978204ebb";
			document.head.appendChild(rtscript);
		}

		const loadScript = (src) => {
			return new Promise((resolve, reject) => {
				const script = document.createElement('script');
				script.type = 'text/javascript';
				script.src = src;
				script.async = true;
				script.onload = () => resolve();
				script.onerror = () => reject(new Error(`Script load error for ${src}`));
				document.head.appendChild(script);
			});
		};

		let hostname = window.location.hostname;
		let scriptUrl = "https://a.remarketstats.com/px/smart/?c=26aa9e6b9bc4721";

		switch (hostname) {
			case "firstadvantage.io":
				scriptUrl += "&seg=firstadvantage";
				break;
			case "app.firstadvantage.io":
				scriptUrl += "&seg=app_firstadvantage";
				break;
			case "firstadvantagefinancial.io":
				scriptUrl += "&seg=firstadvantagefinancial";
				break;
			case "first-advantage.io":
				scriptUrl += "&seg=first-advantage";
				break;
			case "first-advantage-financial.com":
				scriptUrl += "&seg=first-advantage-financial-com";
				break;
			case "first-advantage-financial.io":
				scriptUrl += "&seg=first-advantage-financial-io";
				break;
			case "first-financial.io":
				scriptUrl += "&seg=first-financial";
				break;
			case "firstadvantageresult.com":
				scriptUrl += "&seg=firstadvantageresult";
				break;
			case "firstadvantageassist.com":
				scriptUrl += "&seg=firstadvantageassist";
				break;
			case "firstadvantagebenefit.com":
				scriptUrl += "&seg=firstadvantagebenefit";
				break;
			case "firstadvantagegroup.com":
				scriptUrl += "&seg=firstadvantagegroup";
				break;
			case "firstadvantagepartners.com":
				scriptUrl += "&seg=firstadvantagepartners";
				break;
			case "firstadvantageco.com":
				scriptUrl += "&seg=firstadvantageco";
				break;
			case "firstadvantagesolution.com":
				scriptUrl += "&seg=firstadvantagesolution";
				break;
			case "joinfirstadvantage.com":
				scriptUrl += "&seg=joinfirstadvantage";
				break;
			case "firstadvantagefinance.com":
				scriptUrl += "&seg=firstadvantagefinance";
				break;
			case "firstadvantageadvisors.com":
				scriptUrl += "&seg=firstadvantageadvisors";
				break;
			case "firstadvantageservices.com":
				scriptUrl += "&seg=firstadvantageservices";
				break;
			case "firstadvantagefinancials.com":
				scriptUrl += "&seg=firstadvantagefinancials";
				break;
			case "first-advantage-finance.com":
				scriptUrl += "&seg=first-advantage-finance";
				break;
			case "firstadvantageassociates.com":
				scriptUrl += "&seg=firstadvantageassociates";
				break;
			case "myfirstadvantage.com":
				scriptUrl += "&seg=myfirstadvantage";
				break;
			case "firstadvantagehq.com":
				scriptUrl += "&seg=firstadvantagehq";
				break;
			default:
				break;
		}

		loadScript(scriptUrl)
			.then(() => {
				console.log("Script loaded successfully");
			})
			.catch((error) => {
				console.error(error);
			});
	})
</script>

<template>
	<Header
		:progressPercent="progressPercent"
		:showProgress="currentQuestion.showProgress"
		:backButtonVisible="backButtonVisible"
		:advertiser="advertiser"
		@goBack="goBack" />
	<Main
		v-if="!showSpinner && !displayThankYou"
		:question="currentQuestion"
		:removeQuestion="removeQuestion"
		:direction="transitionDirection"
		:backButtonVisible="backButtonVisible"
		:firstName="questions.name.first"
		:mainHeadline="mainHeadline"
		:mainSubHeadline="mainSubHeadline"
		@proceed="currentQuestion.submit"
		@goBack="goBack"
		@hideBackButton="hideBackButton"
		@showBackButton="showBackButton" />
	<section v-else-if="showSpinner && !displayThankYou" class="spinnerContainer">
		<Spinner :messages="['Verifying your information...','Checking if you qualify...','Confirming your benefits...']" />
	</section>
	<Thankyou
		v-else-if="displayThankYou"
		:advertiser="advertiser"
	/>
	<Footer />
</template>

<style scoped>
	.spinnerContainer {
		min-height: 550px;
        min-height: 70vh;
        position: relative;
		padding-top: 50px;
	}
</style>
